import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { Link } from 'react-router-dom';

function SearchBar({ onSearch }) {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dropdownRef = useRef(null);

  const API_KEY = '5a7bef04dbad4b7150a842dd7144013f';

  const fetchData = useCallback(async (searchTerm) => {
    try {
      setLoading(true);
      setError(null);
      if (searchTerm.length > 0) {
        const movieResult = await axios(`https://api.themoviedb.org/3/search/movie?query=${searchTerm}&api_key=${API_KEY}&language=fr-FR`);
        const tvResult = await axios(`https://api.themoviedb.org/3/search/tv?query=${searchTerm}&api_key=${API_KEY}&language=fr-FR`);
  
        const movieResultsWithType = movieResult.data.results.map((item) => ({
          ...item,
          media_type: 'movie'
        }));
  
        const tvResultsWithType = tvResult.data.results.map((item) => ({
          ...item,
          media_type: 'tv'
        }));
  
        const combinedResults = [...movieResultsWithType, ...tvResultsWithType];
  
        // Tri des résultats en fonction de la popularité
        const sortedResults = combinedResults.sort((a, b) => {
          // Si la popularité est disponible pour les deux éléments, on les compare
          if (a.popularity && b.popularity) {
            return b.popularity - a.popularity;
          } 
          // Si la popularité est disponible pour l'un des éléments, on le met en avant
          else if (a.popularity) {
            return -1;
          } else if (b.popularity) {
            return 1;
          }
          // Si la popularité n'est disponible pour aucun des éléments, on les compare par défaut
          else {
            return 0;
          }
        });
  
        setData(sortedResults);
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError('Une erreur est survenue lors de la recherche. Veuillez réessayer.');
    }
  }, []);  

  const debouncedFetchData = useCallback(_.debounce(fetchData, 300), [fetchData]);

  useEffect(() => {
    debouncedFetchData(search);
  }, [search, debouncedFetchData]);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.classList.contains("search-bar")
    ) {
      setData([]);
    }
  };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <div className="search-container">
      <input
        type="text"
        className="search-bar"
        placeholder="Rechercher un film ou une série"
        value={search}
        onChange={handleSearchChange}
      />
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="search-icon">
        <path d="M14 5H20" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M14 8H17" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21 11.5C21 16.75 16.75 21 11.5 21C6.25 21 2 16.75 2 11.5C2 6.25 6.25 2 11.5 2" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M22 22L20 20" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      {search.length > 0 && (
        <div className="search-dropdown" ref={dropdownRef}>
          {loading && search.length > 0 && <div className="search-dropdown-item">Chargement...</div>}
          {!loading && !error && search.length !== 0 && data.length === 0 && (
            <div className="search-dropdown-item">Aucun résultat</div>
          )}
          {data.map((item, index) => (
            <Link key={index} to={`/film/${item.id}&media_type=${item.media_type}`} className="search-dropdown-item">
              {item.title || item.name} ({item.media_type === 'movie' ? 'Film' : 'Série'})
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchBar;