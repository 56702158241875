import '../../src/style/Waiting.css';

function Maintenance() {
    return (
        <div className="waiting-container">
            <div className="waiting-content">
                <h2>Le site est actuellement en maintenance</h2>
                <p>Nous effectuons des opérations de maintenance. Veuillez patienter.</p>
                <p>Pour toute assistance, veuillez contacter <a href="https://discordapp.com/users/891985255161032754"><b>nous contacter</b></a>.</p>
                <div className="loader"></div>
            </div>
        </div>
    );
}

export default Maintenance;
