import '../../src/style/Waiting.css';
import { useEffect, useState } from 'react';

function Waiting() {
    const [countdown, setCountdown] = useState(60); // Temps en secondes
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);
  
    useEffect(() => {
      if (countdown === 0) {
        window.location.href = '/';
      }
    }, [countdown]);

    const handleLogout = () => {
        localStorage.removeItem('token'); // Supprimer le token de l'utilisateur
      };

    return (
        <div className="waiting-container">
        <div className="waiting-content">
            <h2>Votre compte n'est pas encore validé</h2>
            <p>Veuillez patienter pendant que nous vérifions votre statut de validation.</p>
            <p>Votre page sera actualisée automatiquement dans {countdown} secondes.</p>
            <a href='/' onClick={handleLogout}>Se déconnecter</a>
            <div className="loader"></div>
        </div>
        </div>
    );
}

export default Waiting;
