import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Login from './pages/Login';
import Home from './pages/Home';
import Reg from './pages/Reg';
import List from './pages/List';
import Waiting from './pages/Waiting';
import Maintenance from './pages/Maintenance';
import RandomMovie from './pages/Mode/RandomMovie.js';
import InfoFilm from './pages/InfoFilm.js';

function App() {
  const [validated, setValidated] = useState(false);
  const [tokens, setTokens] = useState(false);
  const [loading, setLoading] = useState(false);
  const [maintenance, setMaintenance] = useState(true);
  const [role, setRole] = useState(null);

  useEffect(() => {
    const checkMaintenance = async () => {
      try {
        const response = await fetch('https://api.evannbl.com/api/status/maintenance');
        const data = await response.json();

        setMaintenance(data.maintenance[0].maintenance);
      } catch (error) {
        console.error('Erreur lors de la vérification de la maintenance:', error);
        setMaintenance(true);
      }
    };

    checkMaintenance();
  }, []);

  useEffect(() => {
    const checkValidation = async () => {
      try {
        const token = localStorage.getItem('token');

        const response = await fetch('https://api.evannbl.com/api/auth/validate', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.status === 401) {
          localStorage.removeItem('token');
          setLoading(false);
          return;
        }
        const data = await response.json();

        if (!token) {
          setTokens(false);
        } else {
          setTokens(true);
        }

        setValidated(data.validated);
        setRole(data.role);
        setLoading(false);
      } catch (error) {
        console.error('Erreur lors de la vérification de la validation de l\'utilisateur:', error);
        setLoading(false);
      }
    };

    checkValidation();
  }, []);

  console.log('tok :' + tokens)

  if (!tokens){
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Reg />} />
        </Routes>
      </BrowserRouter>
    );
  } else if (maintenance && role !== 'admin') {
    return <Maintenance />;
  } else if (loading && role !== 'admin') {
    return <Waiting />;
  } else if (!validated && role !== 'admin') {
    return <Waiting />;
  } else {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/listes" element={<List />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Reg />} />
            <Route path="/maintenance" element={<Maintenance />} />
            {/* <Route path="*" element={<Login />} /> */}
            <Route path="/random-movie" element={<RandomMovie />} />
            <Route path="/film/:id" element={<InfoFilm />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;