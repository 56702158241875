import React, { useState } from 'react';
import axios from 'axios';
import '../App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Reg() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  
  const handleReg = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://api.evannbl.com/api/auth/register', { 
        username,
        password,
      });
      // window.location.href = '/';
      toast.success('connect', {
        data: {
          title: "Success toastd",
          text: "This is a success messaged",
        },
      });
    } catch (error) {
      toast.warn(error.response.data.message, {
        data: {
          title: "Success toast",
          text: "This is a success message",
        },
      });
    }
  };

  return (
    <div className="Login">
      <ToastContainer />
      <div className="login-container">
        <h2>Inscription</h2>
        <form onSubmit={handleReg}>
          <div className="form-group">
            <label htmlFor="username">Nom:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Mots de passe:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="login-button">Inscription</button>
          <a href='/' className='a_link'>Déja un compte ?</a>
        </form>
      </div>
    </div>
  );
}

export default Reg;