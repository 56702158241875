import { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './Composants/Navbar';
import SearchBar from './Composants/SearchBar';
import 'react-toastify/dist/ReactToastify.css';
import '../../src/style/Home.css';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/navigation';

function Home() {
  const handleSearch = (searchTerm) => { };
  const API_KEY = '5a7bef04dbad4b7150a842dd7144013f';
  const [nowPlayingMovies, setNowPlayingMovies] = useState([]);
  const [nowPopularMovies, setNowPopularMovies] = useState([]);
  const [upcomingMovies, setUpcomingMovies] = useState([]);

  const fetchNowPlayingMovies = async () => {
    try {
      const nowPlaying = await axios(`https://api.themoviedb.org/3/movie/now_playing?api_key=${API_KEY}&language=fr-FR`);
      setNowPlayingMovies(nowPlaying.data.results);
    } catch (err) {
      console.error('Une erreur est survenue lors de la récupération des films actuellement au cinéma:', err);
    }
  };

  const fetchNowPopularMovies = async () => {
    try {
      const nowPopular = await axios(`https://api.themoviedb.org/3/trending/all/day?api_key=${API_KEY}&language=fr-FR`);
      setNowPopularMovies(nowPopular.data.results);
    } catch (err) {
      console.error('Une erreur est survenue lors de la récupération des films populaires:', err);
    }
  };

  const fetchUpcomingMovies = async () => {
    const today = new Date().toISOString().split('T')[0]; // Date actuelle au format AAAA-MM-JJ
    const movies = [];
    let page = 1;

    while (movies.length < 20) {
      try {
        const response = await axios(`https://api.themoviedb.org/3/movie/upcoming?api_key=${API_KEY}&language=fr-FR&page=${page}`);
        const results = response.data.results.filter(movie => movie.release_date > today);
        movies.push(...results);

        if (response.data.total_pages <= page) {
          break;
        }

        page += 1;
      } catch (err) {
        console.error('Une erreur est survenue lors de la récupération des films à venir:', err);
        break;
      }
    }

    setUpcomingMovies(movies.slice(0, 20)); // Limiter la liste à 20 films
  };

  useEffect(() => {
    fetchNowPlayingMovies();
    fetchNowPopularMovies();
    fetchUpcomingMovies();
  }, []);

  return (
    <div className="Home">
      <Navbar />
      <div className="content">
        <SearchBar onSearch={handleSearch} />
        <div className="container-top-movie">
          <div className="div-top-movie">
            <Swiper
              className="mySwiper Top-upcoming"
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Navigation]}
              navigation={true}
            >
              {upcomingMovies.map((movie, index) => (
                <SwiperSlide className="movie-item-top">
                  <Link key={index} to={`/film/${movie.id}&media_type=movie`}>
                    <img className="movie-item-image-top" src={`https://image.tmdb.org/t/p/original/${movie.backdrop_path}`} alt={movie.title} />
                    <div className='shadow-top'></div>
                    <p className="movie-item-Texte-top">{movie.name || movie.title}</p>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="div-top-movie-right">
            <p>Erreur 30000</p>
          </div>
        </div>
        <div className="container-movie-grid">
          <div className="div-title-movie-grid">
            <p className="title-movie-grid">Film actuellement au cinéma</p>
          </div>
          <div className="movie-grid">
            <Swiper
              className="mySwiper"
              slidesPerView={7}
              spaceBetween={20}
              loop={true}
              modules={[Navigation]}
              navigation={true}
            >
              {nowPlayingMovies.map((movie, index) => (
                <SwiperSlide className="movie-item">
                  <Link key={index} to={`/film/${movie.id}&media_type=movie`}>
                    <img className="movie-item-image" src={`https://image.tmdb.org/t/p/w500/${movie.poster_path}`} alt={movie.title} />
                    <p className="movie-item-Texte">{movie.name || movie.title}</p>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className="container-movie-grid">
          <div className="div-title-movie-grid">
            <p className="title-movie-grid">Les plus populaires aujourd'hui</p>
          </div>
          <div className="movie-grid">
            <Swiper
              className="mySwiper"
              slidesPerView={7}
              spaceBetween={20}
              loop={true}
              modules={[Navigation]}
              navigation={true}
            >
              {nowPopularMovies.map((movie, index) => (
                <SwiperSlide className="movie-item">
                  <Link key={index} to={`/film/${movie.id}&media_type=movie`}>
                    <img className="movie-item-image" src={`https://image.tmdb.org/t/p/w500/${movie.poster_path}`} alt={movie.title} />
                    <p className="movie-item-Texte">{movie.name || movie.title}</p>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;