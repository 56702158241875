import React, { useState } from 'react';
import axios from 'axios';
import '../style/Login.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.evannbl.com/api/auth/login', {
        username,
        password,
      });
      const { token } = response.data;
      localStorage.setItem('token', token);
      window.location.href = '/';
      toast.success('connect', {
        data: {
          title: "Success toastd",
          text: "This is a success messaged",
        },
      });
    } catch (error) {
      toast.warn(error.response.data.message, {
        data: {
          title: "Success toast",
          text: "This is a success message",
        },
      });
    }
  };

  return (
    <div className="Login">
      <ToastContainer />
      <div className="login-container">
        <h2>Connection</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="username">Nom:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Mots de passe:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="login-button">Connection</button>
          <a href='/register' className='a_link'>Pas encore de compte ?</a>
        </form>
      </div>
    </div>
  );
}

export default Login;