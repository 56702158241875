import React, { useState, useEffect } from "react";
import { useParams } from 'react-router';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Composants/Navbar';
import '../style/InfoFilm.css';
import SearchBar from './Composants/SearchBar';

function InfoFilm() {
  const { id } = useParams();
  const mediaType = id.includes('&media_type=tv') ? 'tv' : 'movie';
  const [details, setDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [username, setCurrentUser] = useState(null);
  const handleSearch = (searchTerm) => { };

  const API_KEY = '5a7bef04dbad4b7150a842dd7144013f';

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        setError(null);

        const [movieId, mediaType] = id.split('&media_type=');

        if (mediaType === 'movie') {
          const response = await axios.get(`https://api.themoviedb.org/3/movie/${movieId}?api_key=${API_KEY}&language=fr-FR`);
          setDetails(response.data);
        } else if (mediaType === 'tv') {
          const response = await axios.get(`https://api.themoviedb.org/3/tv/${movieId}?api_key=${API_KEY}&language=fr-FR`);
          setDetails(response.data);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError('Une erreur est survenue lors de la récupération des détails.');
      }
    };

    fetchDetails();
  }, [id]);

  useEffect(() => {
      const fetchCurrentUser = async () => {
          try {
              const token = localStorage.getItem('token');
              const userResponse = await axios.get('https://api.evannbl.com/api/auth/validate', {
                  headers: {
                      Authorization: `Bearer ${token}`
                  }
              });
              setCurrentUser(userResponse.data.username);
          } catch (error) {
              toast.error('Erreur lors de la récupération de l\'utilisateur actuel.');
          }
      };

      fetchCurrentUser();
  }, []);

  const addToMovieList = async (movieId, title, image) => {
    try {
      const response = await axios.get('https://api.evannbl.com/api/movie/count');
      const position = response.data.count + 1;
      await axios.post('https://api.evannbl.com/api/movie/add', {
        movieId,
        title,
        image,
        position,
        username
      });
      toast.success(`Le film ou la série "${title}" a été ajouté(e) à la liste !`);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="InfoFilm Home">
      <Navbar />
      <ToastContainer />
      {loading ? (
        <h3>Chargement...</h3>
      ) : error ? (
        <h3>{error}</h3>
      ) : (
        <div className="details-container">
          <SearchBar onSearch={handleSearch} />
          <div className="poster-container">
            {details.poster_path ? (
              <img src={`https://image.tmdb.org/t/p/w500/${details.poster_path}`} alt={details.title || details.name} />
            ) : (
              <div className="no-poster">Pas d'affiche disponible</div>
            )}
          </div>
          <div className="info-container">
            <h2>{details.title || details.name}</h2>
            <p className="tagline">{details.tagline}</p>
            <p className="overview">{details.overview}</p>
            <div className="additional-info">
              <p><strong>Date de sortie:</strong> {details.release_date || details.first_air_date}</p>
              <p><strong>Note moyenne:</strong> {details.vote_average}</p>
              {mediaType === 'tv' && (
                <p><strong>Nombre de saisons:</strong> {details.number_of_seasons}</p>
              )}
              {mediaType === 'movie' && (
                <p><strong>Durée:</strong> {details.runtime} minutes</p>
              )}
              <p><strong>Genres:</strong> {details.genres.map(genre => genre.name).join(', ')}</p>
              <p><strong>Langue originale:</strong> {details.original_language}</p>
            </div>
          </div>
          <button onClick={() => addToMovieList(details.id, details.title || details.name, `https://image.tmdb.org/t/p/w300/${details.poster_path}`)}>
                Ajouter à la liste
            </button>
        </div>
      )}
    </div>
  );
}

export default InfoFilm;
