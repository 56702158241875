import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Navbar from '../Composants/Navbar';
import 'react-toastify/dist/ReactToastify.css';
import './RandomMovie.css';

function RandomMovie() {
  const [movies, setMovies] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [result, setResult] = useState(null);
  const [lastIndex, setLastIndex] = useState(null);
  const wheelRef = useRef(null);

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const response = await axios.get('https://api.evannbl.com/api/movie/listes');
        setMovies(response.data.movies);
      } catch (error) {
        console.error('Erreur lors de la récupération des films:', error);
      }
    };

    fetchMovies();
  }, []);

  const getRandomIndex = () => {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * movies.length);
    } while (randomIndex === lastIndex && movies.length > 1);
    return randomIndex;
  };

  const spinWheel = () => {
    if (!spinning && movies.length > 1) {
      setSpinning(true);
      const randomIndex = getRandomIndex();
      setLastIndex(randomIndex);

      const degrees = 360 * 5 + (randomIndex * (360 / movies.length)); // Tourner la roue environ 5 tours + un supplément pour atteindre l'indice sélectionné
      wheelRef.current.style.transition = 'none'; // Supprimer la transition existante
      wheelRef.current.style.transform = `rotate(0deg)`; // Remettre la rotation à 0 degré
      setTimeout(() => {
        wheelRef.current.style.transition = 'all 3s ease-out'; // Animation de rotation de 3 secondes
        wheelRef.current.style.transform = `rotate(${degrees}deg)`; // Tourner la roue à l'angle calculé
        setTimeout(() => {
          setResult(movies[randomIndex]);
          setSpinning(false);
        }, 3000); // Réinitialiser la roue après 3 secondes (durée de l'animation)
      }, 100); // Attendre 100 millisecondes avant d'appliquer la transition pour que la remise à zéro de la rotation soit instantanée
    }
  };

  return (
    <div className="Home">
      <Navbar />
      <div className="RandomMovie content-ran">
        <div className="wheel-container">
          <div className={`wheel ${spinning ? 'spinning' : ''}`} ref={wheelRef}>
            {movies.map((movie, index) => (
              <div key={index} className="wheel-slice">
                Film aléatoire
              </div>
            ))}
          </div>
          {result && spinning === false && (
            <div className="result">
              <img src={result.image} alt={result.title} />
              <p>{result.title}</p>
            </div>
          )}
        </div>
        <button onClick={spinWheel} disabled={spinning || movies.length <= 1}>
          {spinning ? 'En cours...' : 'Lancer la roue'}
        </button>
      </div>
    </div>
  );
}

export default RandomMovie;