import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../src/style/Listes.css';
import { FaTrash } from 'react-icons/fa';
import Navbar from './Composants/Navbar';
import { Link } from 'react-router-dom';

function List() {
    const [movies, setMovies] = useState([]);
    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const token = localStorage.getItem('token');
                const userResponse = await axios.get('https://api.evannbl.com/api/auth/validate', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setCurrentUser(userResponse.data);
            } catch (error) {
                console.error('Erreur lors de la récupération de l\'utilisateur actuel:', error);
            }
        };

        fetchCurrentUser();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.evannbl.com/api/movie/listes');
                setMovies(response.data.movies);
                setUsers(response.data.users);
            } catch (error) {
                console.error('Erreur lors de la récupération des films et utilisateurs:', error);
            }
        };

        fetchData();
        const interval = setInterval(fetchData, 2000);

        return () => clearInterval(interval);
    }, []);

    const handleCheckboxChange = async (movieId, username) => {
        try {
            const movie = movies.find(movie => movie.movieId === movieId);
            const isChecked = !!movie.memberWantsToWatch[username];

            await axios.put(`https://api.evannbl.com/api/movie/listes/${movieId}`, {
                username,
                action: isChecked ? 'remove' : 'add'
            });

            setMovies(prevMovies =>
                prevMovies.map(movie =>
                    movie.movieId === movieId ? {
                        ...movie,
                        memberWantsToWatch: {
                            ...movie.memberWantsToWatch,
                            [username]: !isChecked
                        }
                    } : movie
                )
            );
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la liste de souhaits:', error);
        }
    };

    const handleDeleteMovie = async (movieId) => {
        try {
            await axios.delete(`https://api.evannbl.com/api/movie/listes/${movieId}`);
            setMovies(prevMovies => prevMovies.filter(movie => movie.movieId !== movieId));
        } catch (error) {
            console.error('Erreur lors de la suppression du film:', error);
        }
    };

    const filteredMovies = movies.filter(movie =>
        movie.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="Home">
            <Navbar />
            <div className="movie-list-container">
                <input
                    type="text"
                    placeholder="Rechercher un film..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="movie-list-header">
                    <div className="movie-list-title">Titre du Film</div>
                    {users.map(user => (
                        <div key={user._id} className="movie-list-user">{user.username}</div>
                    ))}
                    <div className="movie-list-action">Action</div>
                </div>
                {filteredMovies.map(movie => (
                    // http://localhost:3001/film/11316&media_type=tv
                    <div key={movie.movieId} className="movie-list-item">
                        <div className="movie-list-title">{movie.title}</div>
                        {users.map(user => (
                            <div key={user._id} className="movie-list-user">
                                <input
                                    type="checkbox"
                                    checked={!!movie.memberWantsToWatch[user.username]}
                                    onChange={() => handleCheckboxChange(movie.movieId, user.username)}
                                    disabled={!currentUser || (!currentUser.role.includes('admin') && currentUser.username !== user.username)}
                                />
                            </div>
                        ))}
                        <div className="movie-list-action">
                            <button onClick={() => handleDeleteMovie(movie.movieId)} className="delete-button">
                                <FaTrash />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default List;
